import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { InactivityLogoutComponent } from './auth/inactivity-logout/inactivity-logout.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { OktaLoginComponent } from './auth/okta-login/okta-login.component';
import { OktaCallbackComponent } from './auth/okta-callback/okta-callback.component';
import { AuthInfoComponent } from './auth/auth-info/auth-info.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MatButtonModule,
  ],
  declarations: [
    InactivityLogoutComponent,
    LogoutComponent,
    OktaCallbackComponent,
    OktaLoginComponent,
    AuthInfoComponent,
  ],
  exports: [
    InactivityLogoutComponent,
    LogoutComponent,
    OktaCallbackComponent,
    OktaLoginComponent,
    AuthInfoComponent,
  ],
})
export class SharedComponentsAuthModule {}
