/* eslint-disable no-eval */
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import {
  LOGGEDINUSER,
  UserAgentService,
  User,
  AppInitializerService,
  AppService,
  GTagService,
  NavigationHelper,
} from '@lc/core';

@Component({
  selector: 'lc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private appInitializer: AppInitializerService,
    private gtagService: GTagService,
    public router: Router,
    store: Store<any>,
    userAgentService: UserAgentService,
  ) {
    userAgentService.doBrowserCheck();
    appInitializer.initializeKeys('lc-coordinator-app', AppService.productSuiteName);

    const user$: Observable<User> = store.select(LOGGEDINUSER).pipe(shareReplay(1));
    NavigationHelper.urlChanged$(router).pipe(
      switchMap((url) => user$.pipe(map((user) => ({ user, url })))),
    ).subscribe(({ user, url }) => {
      // Update the google tag service
      const gtags = new Map([['prod', 'GTM-TWJ36QJ']]);
      this.gtagService.updateGTag(gtags, user, url, 'Coordinator');

      // Refresh notifications
      this.appInitializer.refreshNotifications(url);
    }, (error) => { throw new Error(error); });
  }
}
