import { Component, OnInit } from '@angular/core';
import { AuthenticationService, LOGGEDINUSER, User } from '@lc/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'lc-auth-info',
  templateUrl: './auth-info.component.html',
})
export class AuthInfoComponent implements OnInit {
  info$: Observable<{ userId: string, token: string }>;

  constructor(
    private authenticationService: AuthenticationService,
    private store: Store<any>,
  ) {
    this.info$ = this.store.pipe(
      select(LOGGEDINUSER),
      filter((user) => user != null),
      map((user: User) => ({ userId: user._id, token: this.authenticationService.getToken() })),
    );
  }

  ngOnInit() {
  }
}
