<div class="layout-wrapper layout-1 layout-without-sidenav mat-app-background" [ngClass]="((theme$ | async) || isPluginDarkTheme) ? 'dark-theme' : 'light-theme'">
  <div class="layout-inner">
    <div *ngIf="maintenanceMode$ | async" class="disable-overlay text-center text-danger fixed fx-column x-center y-center">
      <h2 class="bg-dark p-3 text-white">Listing Concierge is temporarily offline while we make updates to improve your experience.<br> We will be back online soon!</h2>
    </div>
    <ng-container *ngFor="let message of messages$ | async">
      <div *ngIf="!message.dismissed" class="px-3 fx-row x-space-between y-center">
        [ngClass]="'message-severity-' + message.severity">
        {{ message.message }}
        <button *ngIf="message.dismissible" mat-icon-button (click)="dismiss(message)">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </ng-container>

    <lc-toolbar></lc-toolbar>

    <div class="layout-container py-0">

      <div class="layout-content">
        <div class="flex-grow-1">
        <div *ngIf ="sessionTimeCounter" class="session-timer">
          <span id="timerSpan" >Session time left  <span class="timer-content">{{timeLeft | minuteSeconds}}</span></span>
        </div>
          <router-outlet></router-outlet>
        </div>
        <hr class="m-0">

        <lc-footer></lc-footer>

        <lc-loader></lc-loader>
      </div>
    </div>
  </div>
</div>
