import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService, AppService } from '@lc/core';

@Component({
  selector: 'lc-inactivity-logout',
  templateUrl: './inactivity-logout.component.html',
  styleUrls: ['./inactivity-logout.component.scss'],
})
export class InactivityLogoutComponent implements OnInit {
  @Input() title: string;

  readonly appName: string = AppService.get('applicationName');

  constructor(
    public authService: AuthenticationService,
  ) { }

  async ngOnInit() {
    await this.authService.logout();
  }

  login() {
    this.authService.redirectToLogin();
  }
}
