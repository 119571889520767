<div [ngClass]="{'dnd-container-vertical-scrollbar':verticalScrollbar, containerClass:true, 'row':true}" cdkDropListGroup>
  <div id="placeholder" cdkDropList  (cdkDropListDropped)="dropListDropped()"></div>
    <div cdkDropList [cdkDropListData]="[item]" *ngFor="let item of items; let i = index"
      (cdkDropListDropped)="dropListDropped()"
      [cdkDropListDisabled]="!canDrag || item.disabled" [cdkDropListConnectedTo]="additionalDrops">

    <div *ngIf="gridPlaceholder" id="grid-manage">
      <div cdkDrag [ngClass]="itemClass" (cdkDragMoved)="dragMoved($event);" (cdkDragDropped)="hidePlaceholder()">
        <div class="cdk-custom-grid-drag-placeholder" *cdkDragPlaceholder></div>
        <template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{item: item, index: i}"></template>
      </div>
    </div>
    <div *ngIf="!gridPlaceholder" id="grid-product">
      <div cdkDrag [ngClass]="itemClass" (cdkDragMoved)="dragMoved($event);" (cdkDragDropped)="hidePlaceholder()">
        <div class="cdk-custom-product-drag-placeholder" *cdkDragPlaceholder></div>
        <template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{item: item, index: i}"></template>
      </div>
    </div>
  </div>
</div>