import { Component, Input, OnInit } from '@angular/core';
import { OktaAuthenticationProvider } from '@lc/core';
import { Router } from '@angular/router';

@Component({
  selector: 'lc-app-okta-login',
  templateUrl: './okta-login.component.html',
  styleUrls: ['./okta-login.component.scss'],
})
export class OktaLoginComponent implements OnInit {
  @Input() title: string;

  constructor(
    private oktaAuthService: OktaAuthenticationProvider,
    private router: Router,
  ) {}

  ngOnInit() {
    if (this.oktaAuthService.getToken()) {
      this.router.navigate(['login', 'callback']);
    } else {
      this.oktaAuthService.login();
    }
  }
}
